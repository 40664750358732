import React, { useState } from "react"
import { dataStore, pageStore, filterStore } from "state/store-zustand";

import dayjs from 'dayjs'
dayjs.extend(isBetween)
import isBetween from 'dayjs/plugin/isBetween'


export const eventTags = [
  "All Ages",
  "Animals & Pets",
  "Art & Culture",
  "Books",
  "Business",
  "Charity & Causes",
  "Comedy",
  "Concerts",
  "Community",
  "Craft",
  "Dance",
  "Education",
  "Family",
  "Featured",
  "Festive",
  "Fashion",
  "Film",
  "Food & Drink",
  "Free",
  "Government",
  "Happy Hour",
  "Health",
  "Hobbies",
  "Holiday",
  "Home & Lifestyle",
  "LGBTQ",
  "Music",
  "Networking",
  "Nightlife",
  "Online",
  "Outdoors",
  "Other",
  "Performing Arts",
  "Spiritual",
  "School Activities",
  "Science & Tech",
  "Seasonal & Holiday",
  "Shopping",
  "Sports & Recreation",
  "Theater",
  "Theatre",
  "Tours",
  "Trivia",
  "Travel & Outdoor",
  "Volunteer",
  "Wellness",
  "Wine",
  "Workshop",
  "Youth",
]

export const isEventTag = (tag) => {
  const isEventTag = eventTags.some(eventTag => eventTag.toLowerCase() === tag.toLowerCase());
  return isEventTag
}


export const activitiesToOptions = (items) => {
  const activityOptions = items.map(item => {

    // TODO: Overwrite title with focus keyword, when present
    const seoTitle = item?.seo && item?.seo?.focuskw && item.seo.focuskw.length > 0
      ? item.seo.focuskw
      : item?.title ? item.title : item.name

    const option = {
      key: item.slug,
      label: seoTitle,
      seo: item.seo,
      title: item.name,
      value: item.slug,
      level: item.level
    }

    return option
  })

  return activityOptions
}


// TODO: Move to utils
export const slugify = (text) => {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/[^\w-]+/g, '')        // Remove all non-word chars
    .replace(/--+/g, '-')           // Replace multiple - with single -
    .replace(/^-+/, '')             // Trim - from start of text
    .replace(/-+$/, '')             // Trim - from end of text
}

export const isDateFormatYYYYMMDD = (str) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(str);
}

export const getDatesFromRange = (
  date_range = 'month',
  start_date = null,
  end_date = null,
  is_custom = false
) => {

  if (start_date) {
    // Check if it's a string is 'YYYY-MM-DD' or 'DD-MM-YYYY
    const start_date_slashes = start_date && typeof start_date === 'string'
      ? start_date.replace(/-/g, '/')
      : start_date;

    const start_with_year = isDateFormatYYYYMMDD(start_date_slashes)  // true
    start_date = dayjs(start_date_slashes).format(start_with_year ? 'YYYY/MM/DD' : 'MM/DD/YYYY');
  }

  // Set hours and minute to 00:00
  // Usually today and day_first are the same, unless there's a custom date range
  const day_first = start_date && is_custom
    ? dayjs(start_date).startOf('day')
    : dayjs().startOf('day')

  const day_last = end_date && is_custom
    ? dayjs(end_date).endOf('day')
    : dayjs().startOf('day')

  const dayOfWeek = day_first.day() + 1

  let startOffset = 0
  let endOffset = 0

  switch (date_range) {
    case 'day':
      endOffset = 1
      break;

    case 'custom':
      startOffset = 0
      endOffset = day_last.diff(day_first, 'day')
      break;


    case 'weekend':
      endOffset = 8 - dayOfWeek
      break;

    case 'next_week':
      const end_of_next_week = day_first.add(1, 'week').endOf('week')
      endOffset = end_of_next_week.diff(day_first, 'day')
      break;

    case 'month':
      const nextMonth = day_first.clone().add(1, 'month');
      endOffset = nextMonth.diff(day_first, 'days');
      break;

    case 'quarter':
      endOffset = 90
      break;

    case 'half_year':
      endOffset = 180
      break;

    case 'year':
      endOffset = 360
      break;
  }

  let date_range_start = day_first.add(startOffset, 'day').startOf('day')
  let date_range_end = day_first.add(endOffset, 'day').endOf('day') //  TODO Plus range

  return {
    start: date_range_start,
    end: date_range_end
  }
}

const useFilterHooks = (props) => {
  return {
    activitiesToOptions,
    eventTags,
    isEventTag,
    getDatesFromRange,
    slugify
  }
}

export default useFilterHooks
