import axios from 'axios'

import {
  formatPlaces,
  scorePlaces,
} from 'vibemap-constants/dist/helpers.js'

import mapStyles from './map_styles.js'

import { featureCollection, polygon } from "@turf/helpers"
import bbox from "@turf/bbox"
import union from "@turf/union"

import {
  getBestRoute,
  getClusters,
  getDirections,
  getFeaturesInBounds,
  getWaypoints,
} from 'vibemap-constants/dist/map.js'

import geoViewport from '@mapbox/geo-viewport'


export { formatPlaces, getClusters }

export const getHeatmapStyle = () => {
  // TODO: only do this if the main vibe changes?
  let heatmap = helpers
    .getHeatmap(null, this.props.mainVibe)

  // Style the legend
  heatmap.map((rgb, i) => {
    let css_var = '--heatmap_' + (i + 1)
    document.documentElement.style.setProperty(css_var, rgb)
  })

  let mapStyles = this.state.mapStyles

  mapStyles.places_heatmap['heatmap-color'] = [
    "interpolate",
    ["linear"],
    ["heatmap-density"],
    0.0, 'rgba(0, 0, 0, 0.2)',
    0.1, heatmap[0],
    0.2, heatmap[1],
    0.3, heatmap[2],
    0.8, heatmap[3],
    0.9, heatmap[4],
    1.1, heatmap[5]
  ]

  this.setState({ mapStyles: mapStyles })
}

export const listToSlugs = (list) => {
  const slugs = list.map((item) => item?.properties?.slug).sort()
  return slugs
}


export const compareLists = (placesExisting, placesNew, allow_subset = true) => {
  const idsExisting = new Set(placesExisting.map(place => place.id));
  const idsNew = new Set(placesNew.map(place => place.id));

  if (idsExisting.size !== idsNew.size) {
    return false;
  }

  if (allow_subset) {
    // Check if the new list is a subset of the existing list
    for (let id of idsNew) {
      if (!idsExisting.has(id)) {
        return false;
      }
    }
  } else {
    // Check if the lists are identical
    for (let id of idsExisting) {
      if (!idsNew.has(id)) {
        return false;
      }
    }
  }

  return true;
}


export const shufflePlaces = (places) => {
  const shuffled = places.sort(() => Math.random() - 0.5);
  return shuffled;
}


export const getRoute = async (places) => {
  const MAPBOX_TOKEN = process.env.GATSBY_API_MAPBOX_TOKEN

  if (places.length && places.length == 0) {
    return null
  }

  const waypoints = getWaypoints(places)
  const routes = await getDirections(waypoints, MAPBOX_TOKEN)

  if (routes.data.routes && routes.data.routes.length > 0) {
    const bestRoute = getBestRoute(routes)
    return bestRoute
  } else {
    return null
  }

}


export const getTopMarkerClusters = ({
  places = [],
  numMarkers = 20,
  shouldCluster = true,
  clusterSize = 10,
  showOthers = false
}) => {
  let currentNumPlaces = places.length
  // Count the greater of the markers and places
  const numOfMarkers = currentNumPlaces > numMarkers
    ? numMarkers
    : currentNumPlaces

  const placesClustered = shouldCluster ? getClusters(places, clusterSize) : places
  const placesTopInCluster = placesClustered.filter(place => place.properties.top_in_cluster)

  // TOOD: Add option for clustering and show all, instead of clustering doing both
  const topMarkers = shouldCluster
    ? [...places.slice(0, numOfMarkers), placesTopInCluster] // Only show top
    : places

  const otherTopMarkers = places.slice(numOfMarkers).filter(place => {
    return place.properties.top_in_cluster
  }) // Get the remaining places

  if (!showOthers) {
    return topMarkers
  }
  else {
    const numOfOtherMarkersToPick = Math.floor(places.length * 0.1);
    const shuffledOtherMarkers = places.slice(numMarkers).sort(() => 0.5 - Math.random());
    const selectedOtherMarkers = shuffledOtherMarkers.slice(0, numOfOtherMarkersToPick);

    // Combine the top markers and other markers
    const placesTop = [...topMarkers, ...selectedOtherMarkers];
    return placesTop
  }

}


export const getIconSizeFromZoom = (zoom) => {
  const minZoom = 8; // the zoom level at which the size should be 40
  const maxZoom = 16; // the zoom level at which the size should be 70

  const minSize = 35;
  const maxSize = 65;

  const clampedZoom = Math.max(minZoom, Math.min(maxZoom, zoom)); // ensure the zoom level is between minZoom and maxZoom
  const t = (clampedZoom - minZoom) / (maxZoom - minZoom); // calculate the interpolation factor

  const size = minSize + t * (maxSize - minSize); // interpolate between minSize and maxSize
  return size;
}


export const mergePlaces = (listA, listB, key = 'id') => {
  const merged = Array.from([...listA, ...listB]
    .reduce((m, o) => m.set(o['id'], o), new Map)
    .values()
  )

  return merged
}


export const decodeFeature = (feature) => {
  if (feature.id === undefined) feature.id = feature.properties.id
  feature.properties.categories = (typeof (feature.properties.vibes) != 'object')
    ? JSON.parse(feature.properties.categories)
    : feature.properties.categories
  feature.properties.vibes = (typeof (feature.properties.vibes) != 'object')
    ? JSON.parse(feature.properties.vibes)
    : feature.properties.vibes
  feature.properties.num_vibes = feature.properties.vibes.length
  //feature.properties.tips = JSON.parse(feature.properties.tips)
  feature.properties.subcategories = feature?.properties?.subcategories
    ? (typeof (feature.properties.subcategories) != 'object')
      ? JSON.parse(feature.properties.subcategories)
      : feature.properties.subcategories
    : []
  feature.properties.opening_hours = feature?.properties?.opening_hours
    ? typeof (feature.properties.opening_hours) != 'object'
      ? JSON.parse(feature.properties.opening_hours)
      : feature.properties.opening_hours
    : []
  //console.log('feature.properties: ', feature.properties.thumbnail_url)
  feature.properties.images = [feature.properties.thumbnail_url]

  delete feature.properties.tips
  //delete feature.properties.subcategories
  delete feature.properties.facebook
  delete feature.properties.telephone
  delete feature.properties.website

  //delete feature['_vectorTileFeature']

  // TODO: fix uncategories spots
  if (feature.properties.categories.length < 1 && feature.properties.vibes.length > 2) {
    //console.log('place missing category: ', feature.properties.name, feature.properties)
  }
  // TODO: Fix drinking spots
  //if (feature.properties.categories.includes('drink')) console.log('fix this category category: ', feature.properties.name, feature.properties.categories)
  return feature
}

// TODO: These could also be moved to vibemap-constants
export const boundsFromPlaces = (geojson, bounds = []) => {

  //const newBounds = new mapboxgl.LngLatBounds(bounds[0], bounds[1])
  //console.log('newBounds: ', newBounds, bounds)
  //places.forEach((place) => {
  //  newBounds.extend(place.geometry.coordinates)
  //})
  try {
    const newBounds = bbox(geojson)
    return newBounds
  } catch (error) {
    console.log('error with places ', error);
    return bounds
  }
}


export const polygonFromMultiPolygon = (multiPolygon, geometryOnly = true) => {
  const polygonType = multiPolygon && multiPolygon?.type
  const isMultiPolygon = polygonType == 'MultiPolygon'
  const hasCoords = multiPolygon && multiPolygon?.coordinates?.length > 0
  const hasOnlyOne = multiPolygon && multiPolygon.coordinates.length == 1

  if (!isMultiPolygon || !hasCoords ) {
    return null
  }

  if (hasOnlyOne) {
    const polygon = geometryOnly
      ? multiPolygon?.coordinates[0][0]
      : multiPolygon?.coordinates[0]
    return polygon
  }

  // If there are nested polygons, union them
  const polygons = multiPolygon.coordinates.map((shape) => {
    return polygon(shape)
  })

  if (polygons.length < 2) {
    const polygonNew = polygons[0]
    return geometryOnly ? polygonNew?.geometry : polygonNew
  }

  // Combine multiple polygons into a single union
  const collection = featureCollection(polygons)
  let polygonNew = union(collection)
  const polygonToReturn = geometryOnly
    ? polygonNew?.geometry.coordinates[0]
    : polygonNew

  return polygonToReturn
}

// Takes an array of points or coordiates and creates a GeoJSON polygon
export const featureFromCoordinates = (coordiates) => {

  const isMultiPolygon = typeof coordiates === 'object' && coordiates.type === 'MultiPolygon'

  let turfFeature = isMultiPolygon
    ? {
      "type": "MultiPolygon",
      "coordinates": []
    }
    : {
      "type": "Polygon",
      "coordinates": []
    }

  // If multi-polygon, use the first polygon
  const points = isMultiPolygon
    ? coordiates.coordinates
    : typeof coordiates === 'string'
      ? JSON.parse(coordiates)
      : coordiates.coordinates[0]

  if (points) {
    turfFeature['coordinates'] = isMultiPolygon ? points : [points]
  }

  return turfFeature
}


export const mapBoxBoundsFromBounds = (bounds) => {
  const [minLng, minLat, maxLng, maxLat] = bounds

  const mapboxBounds = [
    [minLng, minLat],
    [maxLng, maxLat],
  ]

  return mapboxBounds
}


export const flatBoundsFromBounds = (bounds) => {
  const flatBounds = bounds[0].concat(bounds[1])
  return flatBounds
}

export const locationFromGeometry = (geometry) => {
  const [lng, lat] = geometry.coordinates
  return {
    latitude: lat,
    longitude: lng,
  }
}


export const getAllBoundaries = async (admin_level = 'both') => {
  const random = Math.random()
  const include_bounds = false
  const endpoint = `https://api.vibemap.com/v0.3/boundaries/?admin_level=${admin_level}&bounds=${include_bounds ? 1 : 0}&include_hidden=1&per_page=1000&random=${random}`
  // console.log('DEBUG: getAllBoundaries endpoint ', endpoint);
  const response = await axios.get(endpoint).catch(error => {
    console.log(`error `, error)
    return {
      error: true,
      data: []
    }
  })

  return response.data
}


export const getBoundary = async (slug = 'chicago', cache_bust = true) => {
  const random = Math.random()
  const endpoint = `https://api.vibemap.com/v0.3/boundaries/?admin_level=both&slug=${slug}${cache_bust ? `&refresh=${random}` : ''}`
  const response = await axios.get(endpoint).catch(error => {
    console.log(`error `, error)
  })

  if (response && response.data) {
    try {
      const boundary = response.data.results[0] || null
      return boundary
    } catch (error) {
      console.log('Problem with boundary data ', error);
      return null
    }

  } else {
    return null
  }
}


export const featuresToBounds = (mapObject, features, fitPlacesToBounds) => {
  // Update places to only fit map viewport/bounds
  if (mapObject) {
    const currentBounds = mapObject
      ? mapObject.getBounds().toArray()
      : bounds

    try {
      const featuresInBounds = fitPlacesToBounds
        ? getFeaturesInBounds(features, currentBounds)
        : features

      //console.log('SW bounds ', bounds[0]);
      //console.log('Top place in bounds ', placesInBounds[0]);
      return featuresInBounds
    } catch (error) {
      console.log('error with features ', error);
    }
  }
}


export const viewportFromBounds = (bounds, viewport, padding = 10) => {
  const [minLng, minLat, maxLng, maxLat] = bounds

  const mapboxBounds = [
    [minLng, minLat],
    [maxLng, maxLat],
  ]

  const height = viewport.height ? viewport.height : 480
  const width = viewport.width ? viewport.width : 640
  const minZoom = 0
  const maxZoom = 22
  const tileSize = 512
  const allowFloat = true
  const viewportCenter = geoViewport.viewport(bounds, [width, height], minZoom, maxZoom, tileSize, allowFloat)

  const viewportNew = {
    ...viewport,
    latitude: viewportCenter.center[1],
    longitude: viewportCenter.center[0],
    zoom: viewportCenter.zoom - 0.4,
  }

  return viewportNew
}


export const placesFromTile = (features, type, bounds, centerpoint, zoom) => {
  const MIN_NUM_VIBES = 2
  const features_in_bounds = getFeaturesInBounds(features, bounds)
  const activity = 'all'
  const ordering = "relevance"
  const scoreBy = ['aggregate_rating', 'categories', 'vibes', 'distance', 'offers', 'hours']
  const vibes = []
  const all_vibes = []

  const places_decoded = features_in_bounds.map((feature) => decodeFeature(feature))

  const places_decoded_filtered = places_decoded.filter(place => place.properties.num_vibes > MIN_NUM_VIBES)

  const places_formatted = formatPlaces(places_decoded_filtered)

  // Filter based on categories
  // TODO: This will become a stored procedure in Postgres before too long
  const places_filtered_category = places_formatted.filter(function (place) {
    return (activity === 'all' || place.properties.categories.includes(activity))
  })

  const places_scored_and_sorted = scorePlaces(
    places_filtered_category,
    centerpoint,
    all_vibes,
    scoreBy,
    ordering,
    zoom
  )

  return places_scored_and_sorted
}
